var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"GroupAddForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addGroup($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,692638557)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Team Name","rules":{
            required: true,
            regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"team_name","state":errors.length > 0 ? false : null,"name":"team_name"},model:{value:(_vm.team_name),callback:function ($$v) {_vm.team_name=$$v},expression:"team_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"Description"}},[_c('b-form-textarea',{attrs:{"id":"team_description","name":"team_description"},model:{value:(_vm.team_description),callback:function ($$v) {_vm.team_description=$$v},expression:"team_description"}})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.team_name === '' || _vm.disable,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Add")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }