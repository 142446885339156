<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <validation-observer ref="GroupAddForm" >
      <b-form class="auth-login-form mt-2" @submit.prevent="addGroup">
        <!-- Organization-->
        <b-form-group
          v-if="is_staff"
          label="Organization"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <v-select
              v-model="organization"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              autocomplete
              :reduce="(organization) => organization.tenant_uid"
              disabled
            />
            <!-- <b-form-select
              v-model="organization"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              @change="onOrgChange"
            ></b-form-select> -->
            <!-- {{organization}} -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Team Name -->
        <b-form-group label="Name" label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Team Name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="team_name"
              v-model="team_name"
              :state="errors.length > 0 ? false : null"
              name="team_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Description -->
        <b-form-group label="Description" label-for="Description">
          <b-form-textarea
            id="team_description"
            v-model="team_description"
            name="team_description"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="team_name === '' || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      team_name: "",
      team_description: "",
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      organization: this.$store.state.app.org_id,
      org_id: this.$store.state.app.organizationId,
      // organizations: [],
      users: [{ value: null, text: "--Select Owner--" }],
      isloading: false,
      disable: false,
    };
  },
  created: function () {
    // this.load();
  },
  computed: {
    // organization() {
    //   return this.organizations.find(
    //     (org) => org.value === this.$store.state.app.org_id
    //   );
    // },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.organization) {
        return this.organization.value;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.organization) {
        return this.$store.state.app.organizationId;
      }
      return null;
    },
  },

  methods: {
    // load: function () {
    //   if (this.is_staff) {

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {
    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //     });
    //   }
    // },
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
    },
    addGroup: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        this.disable = true;
        this.isloading = true;
        if (success) {
          //call to axios
          var self = this;
          // if (!this.is_staff) this.organization = this.$store.state.app.org_id;
          let data = {
            team_name: this.team_name,
            team_description: this.team_description,
            // organization: this.selectedOrganizationId,
            organization: this.org_id,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "user/teams/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.team_id) {
              this.$router.push({
                name: "Manage Team",
                params: { id: res.data.team_id },
              });
              // this.$router.push({name: 'Groups'});
              self.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Successfully added group! You may now add assets to your group!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
              this.disable = false;
            }
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>